<template>
  <div>   
    <datatable ref="usersDatatable">
      <template #table-header-actions>
        <BButton variant="primary ml-1" @click="createUser">
          <feather-icon icon="PlusIcon" /> Novo usuário
        </BButton>
      </template>
    </datatable>
  </div>
</template>

<script>
import EventBus from "@/services/event_bus";
import { BCard, BCol, BRow, BButton } from 'bootstrap-vue';
import Datatable from "@/views/common/crud/components/Datatable.vue";

export default {
  name: 'AdminUsersIndex',
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    Datatable,
  },
  mounted() {
    EventBus.$on('block-users', this.blockUsers);
    EventBus.$on('unblock-users', this.unblockUsers);
  },
  methods: {
    createUser () {
      this.$router.push({ name: 'admin-users.create' });
    },
    blockUsers (users) {
      const ids = users.map(user => user.id);
      this.$store.dispatch("admin/blockUsers", ids).then((response) => {
        EventBus.$emit("success", "Usuários bloqueados com sucesso!");
        this.refreshTable();
      });
    },
    unblockUsers (users) {
      const ids = users.map(user => user.id);
      this.$store.dispatch("admin/unblockUsers", ids).then((response) => {
        EventBus.$emit("success", "Usuários desbloqueados com sucesso!");
        this.refreshTable();
      });
    },
    refreshTable () {
      this.$refs.usersDatatable.getData();
    }
  }
}
</script>
